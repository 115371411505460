import React from "react";
import { Link, graphql, StaticQueryDocument } from "gatsby";

import Layout from "../components/layout";
import PageTitle from "../components/pageTitle";

type BlogPageProps = {
  data: {
    allMarkdownRemark: {
      edges: [
        {
          node: {
            html: string;
            frontmatter: {
              title: string;
              date: string;
            };
            fields: {
              slug: string;
            };
          };
        }
      ];
    };
  };
};

type BlogPageState = {
  queryData: {
    allMarkdownRemark: {
      edges: [
        {
          node: {
            html: string;
            frontmatter: {
              title: string;
              date: string;
            };
            fields: {
              slug: string;
            };
          };
        }
      ];
    };
  };
};

export default class BlogPage extends React.Component<
  BlogPageProps,
  BlogPageState
> {
  // Construct blog page class.
  constructor(props: BlogPageProps) {
    super(props);
    this.state = { queryData: props.data };
  }

  // Render blog page.
  render() {
    return (
      <Layout>
        <PageTitle className="mt-10">Blog</PageTitle>
        {!this.state.queryData && (
          <p className="font-polle text-center text-2xl mt-10 mb-auto">
            Oeps! Het ziet ernaar uit dat Moos alle blog artikelen heeft
            opgegeten.
          </p>
        )}
        <div className="grid xl:grid-cols-3 2xl:grid-cols-5 font-polle space-x-10 mt-10">
          {this.state.queryData.allMarkdownRemark.edges.map((edge) => {
            return (
              <Link
                to={edge.node.fields.slug}
                className="bg-yellow-polle rounded-lg p-5 transition-transform duration-500 hover:scale-110"
              >
                <h1 className="text-3xl">{edge.node.frontmatter.title}</h1>
              </Link>
            );
          })}
        </div>
      </Layout>
    );
  }
}

export const PageQuery: StaticQueryDocument = graphql`
  {
    allMarkdownRemark {
      edges {
        node {
          timeToRead
          html
          frontmatter {
            title
            date(formatString: "DD-MM-YYYY")
          }
          fields {
            slug
          }
        }
      }
    }
  }
`;
